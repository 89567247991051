.validation {
  text-align: center;
  padding: 50px 0 100px 0;
  h2 {
    text-transform: uppercase;
    color: $primary;
    span {
      font-weight: 700;
    }
  }
  h6 {
    text-transform: uppercase;
    margin-bottom: 12px;
    color: $gray-600;
  }
  .lead {
    font-weight: 400;
    margin-bottom: 30px;
  }

  ul {
    list-style: none;
    padding: 0;
    margin-bottom: 50px;
    li {
      font-size: 1.4rem;
      margin-bottom: 12px;
      letter-spacing: -0.02rem;
    }
  }
  .logo {
    height: 40px;
  }
}
