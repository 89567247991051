.thanks {
  text-align: center;
  padding-top: 60px;
  h1 {
    margin-bottom: 30px;
  }
  .lead {
    margin-bottom: 30px;
  }
  .button {
    margin-bottom: 30px;
  }
  .card {
    text-decoration: none;
    border-radius: 8px;
    &:hover {
      box-shadow: 0 0 8px $gray-500;
    }
    .card-image {
      height: 160px;
      width: 100%;
      object-fit: cover;
      border-radius: 8px 8px 0 0;
    }
    .card-body {
      // background-color: $primary;
      // color: white;
      // text-transform: uppercase;
      // svg {
      //   margin-right: 10px;
      // }
      .card-title {
        text-transform: uppercase;
        margin-bottom: 0;
        font-size: 1.2rem;
        font-weight: 500;
      }
    }
  }
  .cta {
    background-color: transparent;
    padding: 0;
    color: $gray-800;
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) {
  .thanks {
    text-align: center;
    h1 {
      text-align: center;
      margin-bottom: 10px;
    }
    .lead {
      margin-bottom: 10px;
    }
  }
}
