.benefits {
  padding: 100px 0;

  h1 {
    text-transform: uppercase;
    font-size: 2.4rem;
    margin-bottom: 10px;
    color: $primary;
  }
  h2 {
    margin-bottom: 80px;
    font-size: 1.6rem;
  }

  .card {
    text-align: center;
    background-color: transparent;
    border: none;
    &-image {
      height: 4em;
      width: 4em;
      margin: auto;
      color: $primary;
    }
    &-title {
      text-transform: uppercase;
      font-weight: 400;
      font-size: 1.6rem;
      letter-spacing: 0.06rem;
    }
    &-text {
      //color: red;
      font-size: 1.04rem;
      span {
        font-weight: 500;
        color: $primary;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .benefits {
    padding: 100px 0 50px 0;
    h1 {
      text-transform: uppercase;
      font-size: 2rem;
      color: $primary;
    }
    h2 {
      margin-bottom: 60px;
      font-size: 1.5rem;
    }
    .card {
      margin-bottom: 50px;
    }
  }
}
