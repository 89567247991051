$product-hero-heigth-desktop: 600px;
$product-hero-heigth-mobile: 385px;

.products {
  .products-hero {
    position: relative;
    margin-bottom: 60px;
    .hero-bg {
      height: $product-hero-heigth-desktop;
      width: 100%;
      object-fit: cover;
    }
    .hero-overlay {
      height: $product-hero-heigth-desktop;
      position: absolute;
      z-index: 1;
      top: 0;
      width: 100%;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 60%,
        rgba(0, 0, 0, 0.94) 100%
      );
    }
    .hero-caption {
      position: absolute;
      z-index: 2;
      top: 0;
      width: 100%;
      .container {
        height: $product-hero-heigth-desktop;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        .logo {
          height: 60px;
          margin-bottom: 12px;
        }
        h1 {
          color: white;
          font-weight: 600;
          font-size: 5rem;
          text-transform: uppercase;
          padding-bottom: 10px;
        }
      }
    }
  }

  h2,
  h3 {
    text-transform: uppercase;
    color: $primary;
    margin-bottom: 24px;
  }
  .lead {
    margin-bottom: 60px;
    font-weight: 400;
  }
  .products-grid {
    margin-bottom: 80px;
    display: grid;
    grid-template: repeat(2, 240px) / auto 300px;
    gap: 16px;
    grid-template-areas:
      'item-1 item-2'
      'item-1 item-3';
    .grid-item {
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .item-1 {
      grid-area: item-1;
    }
    .item-2 {
      grid-area: item-2;
    }
    .item-3 {
      grid-area: item-3;
    }
  }
  .products-investment {
    background-color: darken($gray-100, 2%);
    padding: 40px 0 20px 0;

    .card {
      text-align: center;
      background-color: transparent;
      border: none;
      &-image {
        height: 2em;
        width: 2em;
        margin: auto;
        color: $primary;
      }
      &-title {
        text-transform: uppercase;
        font-weight: 500;
        font-size: 1.6rem;
        letter-spacing: 0.06rem;
        color: $gray-700;
      }
      &-text {
        text-transform: uppercase;
        color: $gray-900;
        font-weight: 400;
        font-size: 1.1rem;
        letter-spacing: 0.06rem;
        margin-bottom: 0;
      }
    }
  }
  .products-amenities {
    padding: 60px 0;
    ul {
      margin-top: 30px;
      text-align: left;
      margin-top: 30px;
      list-style: none;
      padding: 0;
      columns: 2;
      gap: 20px;
      li {
        display: grid;
        grid-template-columns: 20px auto;
        //margin-bottom: 8px;
        break-inside: avoid;
        letter-spacing: -0.02rem;
        font-size: 1.1rem;
        line-height: 1.7rem;
        span {
          margin-top: 8px;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: $success;
        }
      }
    }
  }
  .products-gallery {
    margin-bottom: 60px;
    .carousel-inner {
      img {
        width: 100%;
        height: 450px;
        object-fit: cover;
      }
    }
  }
  .products-value {
    background-color: $gray-200;
    padding: 20px 0;
    margin-bottom: 30px;
  }
  .products-others {
    margin-top: 30px;
    margin-bottom: 60px;
    .card {
      .card-image {
        width: 100%;
        height: 360px;
        object-fit: cover;
      }
      .card-body {
        text-align: center;
        height: 210px;
        .card-title {
          font-weight: 700;
          margin-bottom: 4px;
        }
        h5 {
          font-size: 1em;
          text-transform: uppercase;
          margin-bottom: 12px;
        }
        .card-subtitle {
          font-weight: 500;
          font-size: 1.1rem;
          margin-bottom: 8px;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .products {
    //background-color: $gray-100;
    .products-hero {
      position: relative;
      margin-bottom: 60px;
      .hero-bg {
        height: $product-hero-heigth-mobile;
        width: 100%;
        object-fit: cover;
      }
      .hero-overlay {
        height: $product-hero-heigth-mobile;
        position: absolute;
        z-index: 1;
        top: 0;
        width: 100%;
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 60%,
          rgba(0, 0, 0, 0.94) 100%
        );
      }
      .hero-caption {
        position: absolute;
        z-index: 2;
        top: 0;
        width: 100%;
        .container {
          height: $product-hero-heigth-mobile;
          display: flex;
          align-items: flex-end;
          justify-content: center;
          .logo {
            height: 46px;
            margin-bottom: 4px;
          }
          h1 {
            color: white;
            font-weight: 600;
            font-size: 3rem;
            text-transform: uppercase;
            padding-bottom: 10px;
          }
        }
      }
    }
    .lead {
      margin-bottom: 30px;
    }
    .products-grid {
      display: grid;
      grid-template: 200px 150px / auto auto;
      gap: 8px;
      grid-template-areas:
        'item-1 item-1'
        'item-2 item-3';
      .grid-item {
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    .products-investment {
      padding: 60px 0 0 0;
      .card {
        margin-bottom: 30px;
      }
    }
    .products-amenities {
      padding: 60px 0;
      //margin-bottom: 30px;
      ul {
        margin-top: 30px;
        text-align: left;
        margin-top: 30px;
        list-style: none;
        padding: 0;
        columns: 1;
        gap: 20px;
        li {
          display: grid;
          grid-template-columns: 20px auto;
          margin-bottom: 8px;
          break-inside: avoid;
          letter-spacing: -0.02rem;
          span {
            margin-top: 8px;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: $success;
          }
        }
      }
    }
    .products-gallery {
      background-color: $gray-200;
      padding: 20px 0;
      margin-bottom: 30px;
    }
    .products-value {
      background-color: $gray-200;
      padding: 20px 0;
    }
  }
}
