.navbar {
  padding: 20px 0;
  .navbar-brand {
    img {
      height: 60px;
    }
  }
}

@media screen and (max-width: 767px) {
  .navbar {
    padding: 16px 0;
    .navbar-brand {
      img {
        height: 40px;
      }
    }
  }
}
