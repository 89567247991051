html {
  position: relative;
  min-height: 100%;
}

body {
  margin-bottom: 60px;
  text-align: center;

  // demo
  // background-color: $gray-100;
}

// h1 {
//   padding: 60px;
//   font-weight: 300;
//   margin-bottom: 1px;
// }
