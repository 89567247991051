.btn-scroll {
  padding: 12px 36px;
  font-weight: 500;
  border: 1px solid white;
  letter-spacing: 0.04rem;
  text-transform: uppercase;
  font-size: 1.2rem;
  &:hover {
    border: 1px solid rgba(white, 0.6);
  }
}
