.cta {
  background-color: $primary;
  color: white;
  padding: 60px 0;
  margin-bottom: 60px;

  &-content {
    display: flex;
    align-items: center;
    justify-content: center;

    text-align: left;
    .cta-text {
      margin-right: 30px;
      text-transform: uppercase;
      h3 {
        font-weight: 500;
        margin-bottom: 0px;
        font-size: 1.8rem;
      }
      h4 {
        font-size: 1.6rem;
        margin-bottom: 4px;
        span {
          font-weight: 700;
          color: $warning;
          font-size: 1.8rem;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .cta {
    background-color: $primary;
    color: white;
    padding: 50px 0;

    &-content {
      text-align: center;
      display: block;
      .cta-text {
        margin-right: 0px;
        margin-bottom: 30px;
        text-transform: uppercase;
        h3 {
          margin-bottom: 12px;
          font-size: 1.6rem;
        }
        h4 {
          font-size: 1.4rem;
          margin-bottom: 12px;
          span {
            font-weight: 700;
            color: $warning;
            font-size: 1.6rem;
          }
        }
      }
    }
  }
}
