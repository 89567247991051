.hero {
  background: darken($primary, 35%);
  position: relative;
  .hero-bg {
    height: 600px;
    width: 100%;
    object-fit: cover;
    opacity: 0.3;
  }
  .hero-content {
    position: absolute;
    top: 0;
    width: 100%;
    height: 600px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    .hero-antetitle {
      font-size: 1.4rem;
      margin-bottom: 4px;
      text-transform: uppercase;
      letter-spacing: 0.1rem;
    }
    .hero-title {
      margin-bottom: 30px;
      font-weight: 600;
      color: white;
      font-size: 3.6rem;
    }
    .hero-subtitle {
      background-color: $primary;
      text-transform: uppercase;
      display: inline-block;
      padding: 12px 16px;
      margin-bottom: 60px;
      font-weight: 500;
      border-radius: 4px;
    }
  }

  ul {
    color: white;
    list-style: none;
    font-size: 1.6rem;
    margin-bottom: 40px;
    li span {
      text-transform: uppercase;
      font-weight: 600;
    }
  }
}

@media screen and (max-width: 767px) {
  .hero {
    .hero-bg {
      height: 540px;
    }
    .hero-content {
      height: 540px;
      .hero-antetitle {
        font-size: 1.3rem;
        letter-spacing: 0.06rem;
      }
      .hero-title {
        margin-bottom: 24px;
        font-weight: 600;
        color: white;
        font-size: 2.4rem;
      }
      .hero-subtitle {
        padding: 8px 12px;
        margin-bottom: 50px;
        border-radius: 4px;
        font-size: 1.04rem;
      }
    }

    ul {
      color: white;
      list-style: none;
      font-size: 1.6rem;
      margin-bottom: 40px;
      li span {
        text-transform: uppercase;
        font-weight: 600;
      }
    }
  }
}
